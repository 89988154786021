import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/marka-yonetimi-1.jpg";
import serviceImg2 from "../../assets/images/service/marka-yonetim-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Marka Yönetimi Hizmetleri"
            pageDescription="Ketçap Ajans, markanızın değerini ve bilinirliğini artırmak için stratejik marka yönetimi hizmetleri sunar. Güçlü bir marka kimliği ve itibar oluşturmanıza yardımcı oluyoruz."
            pageKeyword="marka yönetimi, marka kimliği, marka stratejisi, dijital pazarlama, marka bilinirliği, itibar yönetimi, Ketçap Ajans, İstanbul"
            pageCanonical="https://ketcap.com/hizmetlerimiz/marka-yonetimi"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--6"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Marka Yönetimi</h2>
                  <p>Güçlü Bir Marka İçin Stratejik Yaklaşımlar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Marka yönetimi, işletmenizin sadece bugünü değil, geleceğini de şekillendiren bir süreçtir. Ketçap Ajans olarak, markanızın benzersiz kimliğini oluşturmak, hedef kitlenizle güçlü bir bağ kurmak ve uzun vadeli başarı sağlamak için stratejik marka yönetimi çözümleri sunuyoruz. Rekabetin yoğun olduğu pazarda, markanızın fark edilmesi ve tercih edilmesi için özgün stratejiler geliştiriyor ve uyguluyoruz.
                          </p>
                          <h4 className="title">Marka Yönetim Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>Marka Analizi:</b> Mevcut marka durumunuzu analiz ederek, güçlü ve zayıf yönlerinizi belirliyoruz.</li>
                            <li><b>Stratejik Planlama:</b> Markanızın hedeflerine ulaşması için kapsamlı ve hedef odaklı bir plan hazırlıyoruz.</li>
                            <li><b>Kimlik Geliştirme:</b> Markanızı rakiplerinizden ayıran, değerlerinizi ve vizyonunuzu yansıtan bir kimlik oluşturuyoruz.</li>
                            <li><b>İletişim ve Uygulama:</b> Tüm iletişim kanallarında markanızın tutarlı ve etkili bir şekilde temsil edilmesini sağlıyoruz.</li>
                            <li><b>Takip ve Optimizasyon:</b> Sürekli analiz ve ölçümlerle stratejileri optimize ederek, markanızın büyümesini destekliyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Marka yönetimi, sürekli ve dinamik bir süreçtir. Ketçap Ajans olarak, markanızın değerini ve etkisini artırmak için stratejik ve yaratıcı çözümler sunuyoruz. Her adımda, markanızın benzersiz kimliğini koruyarak hedef kitlenizle derin ve anlamlı bir bağlantı kurmanızı sağlıyoruz. İhtiyaçlarınıza özel çözümler üreterek, markanızı rakiplerinizden ayıran bir konuma getiriyoruz. Uzun vadeli başarıyı sağlamak için stratejilerimizi sürekli gözden geçiriyor ve geliştiriyoruz.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
