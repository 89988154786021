import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/yazilim-programlama-hizmeti-1.jpg";
import serviceImg2 from "../../assets/images/service/yazilim-programlama-hizmeti-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Yazılım ve Programlama Hizmetleri"
            pageDescription="Ketçap Ajans, özel yazılım geliştirme ve programlama hizmetleri sunar. İşletmenizin ihtiyaçlarına uygun web ve mobil çözümlerle dijital dönüşümünüzü hızlandırın."
            pageKeyword="yazılım geliştirme, programlama hizmetleri, web yazılım, mobil uygulama geliştirme, özel yazılım çözümleri, Ketçap Ajans, dijital dönüşüm, İstanbul"
            pageCanonical="https://ketcap.com/hizmetlerimiz/yazilim-programlama"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--12"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Yazılım/Programlama Hizmeti</h2>
                  <p>İş Süreçlerinizi Dijitalleştirin ve Güçlendirin!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Özel yazılım çözümleri, işletmenizin benzersiz ihtiyaçlarını karşılamak ve iş süreçlerinizi optimize etmek için geliştirilir. Ketçap Ajans olarak, kullanıcı dostu, güvenilir ve ölçeklenebilir yazılım çözümleri sunuyoruz. İster web tabanlı uygulamalar, ister masaüstü yazılımlar veya mobil uygulamalar olsun, işinize özel yazılım geliştirme hizmetlerimizle verimliliğinizi artırın ve dijital dönüşümünüzü hızlandırın.</p>
                          <h4 className="title">Yazılım/Programlama Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>İhtiyaç Analizi ve Danışmanlık:</b> İşletmenizin yazılım gereksinimlerini analiz ediyor ve size en uygun çözümleri sunuyoruz.</li>
                            <li><b>Tasarım ve Prototip Geliştirme:</b> Kullanıcı deneyimini ön planda tutarak, yazılımınızın görsel tasarımını ve işlevselliğini planlıyoruz.</li>
                            <li><b>Yazılım Geliştirme:</b> Modern programlama dilleri ve en iyi uygulama standartlarıyla, ihtiyacınıza özel yazılım çözümleri geliştiriyoruz.</li>
                            <li><b>Test ve Kalite Kontrol:</b> Yazılımın güvenli, hatasız ve performanslı çalışması için kapsamlı testler gerçekleştiriyoruz.</li>
                            <li><b>Kurulum, Eğitim ve Destek:</b> Yazılımın kurulumunu gerçekleştiriyor, ekibinize gerekli eğitimi sağlıyor ve sürekli teknik destek sunuyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Yazılım geliştirme sürecimizde, işletmenizin ihtiyaçlarına odaklanarak her adımda özelleştirilmiş çözümler sunuyoruz. Ketçap Ajans olarak, proje analizinden yazılım tasarımı ve geliştirmeye, testten kurulum ve desteğe kadar tüm aşamalarda yanınızdayız. Amacımız, iş süreçlerinizi dijital dünyaya uyarlayarak rekabet avantajı elde etmenizi sağlamak ve dijital dönüşümünüzü sorunsuz bir şekilde yönetmektir.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
