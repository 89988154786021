import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/e-ticaret-cozumleri-1.jpg";
import serviceImg2 from "../../assets/images/service/e-ticaret-cozumleri-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - E-Ticaret Çözümleri ve Hizmetleri"
            pageDescription="Ketçap Ajans, işletmeniz için özel e-ticaret çözümleri sunar. Güçlü altyapı, kullanıcı dostu tasarım ve güvenli ödeme sistemleri ile çevrimiçi satışlarınızı artırın."
            pageKeyword="e-ticaret çözümleri, e-ticaret sitesi tasarımı, online satış, e-ticaret platformu, ödeme sistemleri, dijital pazarlama, Ketçap Ajans, e-ticaret danışmanlığı"
            pageCanonical="https://ketcap.com/hizmetlerimiz/e-ticaret-cozumleri"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--11"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">E-Ticaret</h2>
                  <p>Dijital Pazarda Güçlü ve Kârlı Bir Varlık Edinin</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>E-ticaret, günümüzün hızla büyüyen dijital pazarında işletmeler için büyük fırsatlar sunar. Ketçap Ajans olarak, işinizi dijital dünyada büyütmek ve satışlarınızı artırmak için kapsamlı e-ticaret çözümleri sunuyoruz. Kullanıcı dostu, mobil uyumlu ve güvenli e-ticaret platformları tasarlayarak, müşterilerinizin alışveriş deneyimini iyileştiriyoruz. Her adımda hedeflerinize ulaşmanızı sağlayacak stratejilerle yanınızdayız.</p>
                          <h4 className="title">E-Ticaret Çözümleri Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>İhtiyaç Analizi ve Strateji Geliştirme:</b> E-ticaret hedeflerinizi ve pazar dinamiklerinizi analiz ederek, işinize özel stratejiler geliştiriyoruz.</li>
                            <li><b>Tasarım ve Kullanıcı Deneyimi (UX/UI):</b> Kullanıcı dostu ve dönüşüm odaklı tasarımlar oluşturarak, ziyaretçilerin müşteriye dönüşmesini sağlıyoruz.</li>
                            <li><b>Platform Seçimi ve Kurulum:</b> Sizin için en uygun e-ticaret platformunu (Shopify, WooCommerce, İkas vb.) seçiyor ve kurulumu gerçekleştiriyoruz.</li>
                            <li><b>Ödeme Sistemleri ve Güvenlik:</b> Güvenli ödeme sistemleri entegre ediyor ve müşteri verilerinizi korumak için gerekli güvenlik önlemlerini alıyoruz.</li>
                            <li><b>Entegrasyon ve Optimizasyon:</b> E-ticaret sitenizi lojistik, muhasebe ve pazarlama araçlarıyla entegre ediyor, performansını artırmak için sürekli optimize ediyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>E-ticaret dünyasında başarı, doğru strateji, kullanıcı odaklı tasarım ve sürekli optimizasyondan geçer. Ketçap Ajans olarak, işinizi dijital pazarda güçlendirmek için ihtiyaçlarınıza özel çözümler sunuyoruz. Her aşamada; strateji geliştirme, platform seçimi, tasarım, entegrasyon ve güvenlik gibi kritik konularda size rehberlik ediyoruz. Amacımız, dijital pazarın getirdiği fırsatları en iyi şekilde değerlendirerek, işinizi sürdürülebilir bir başarıya taşımaktır.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
