import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/sosyal-medya-yonetimi-1.jpg";
import serviceImg2 from "../../assets/images/service/sosyal-medya-yonetimi-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Sosyal Medya Yönetimi Hizmetleri"
            pageDescription="Ketçap Ajans, işletmeniz için etkili sosyal medya stratejileri geliştirir, içerik üretir ve sosyal medya hesaplarınızı yönetir. Marka bilinirliğinizi artırın ve hedef kitlenize ulaşın."
            pageKeyword="sosyal medya yönetimi, sosyal medya stratejisi, içerik üretimi, sosyal medya pazarlaması, dijital pazarlama, Ketçap Ajans, Instagram yönetimi, Facebook yönetimi, sosyal medya analizi"
            pageCanonical="https://ketcap.com/hizmetlerimiz/sosyal-medya-yonetimi"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Sosyal Medya Yönetimi</h2>
                  <p>Dijital Dünyada Fark Edilmek İçin Doğru Strateji</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Sosyal medya, markanızın dijital dünyada güçlü bir şekilde varlık göstermesi için en etkili araçlardan biridir. Biz, hedef kitlenize ulaşmak ve markanızı güçlendirmek için sosyal medya stratejileri geliştiriyoruz. İçerik planlaması, paylaşım takvimi, etkileşim analizi ve performans ölçümü gibi tüm süreçlerde yanınızdayız. Sosyal medya hesaplarınızın sadece bir vitrin olmanın ötesine geçerek müşterilerinizle etkileşim kurmasını sağlıyoruz.
                          </p>
                          <h4 className="title">Sosyal Medya Yönetim Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>Strateji Geliştirme:</b> Markanızın hedeflerine uygun stratejik planlar oluşturuyoruz.</li>
                            <li><b>İçerik Planlaması ve Üretimi:</b> Yaratıcı ve ilgi çekici içerikler hazırlayarak markanızı hedef kitlenize en doğru şekilde yansıtıyoruz.</li>
                            <li>
                              <b>Takip ve Raporlama:</b> Etkileşim ve dönüşüm oranlarını izleyerek, düzenli raporlamalar yapıyoruz.
                            </li>
                            <li><b>Geliştirme ve Optimizasyon:</b> Analiz sonuçlarına göre stratejileri optimize ederek, sürekli gelişim sağlıyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Sosyal medya yönetiminde başarının anahtarı, iyi planlanmış ve sürekli olarak optimize
                            edilen bir süreçtir. Ketçap Ajans olarak, markanızın dijital dünyada en iyi şekilde temsil
                            edilmesi için ihtiyaç analiziyle başlayan, hedef kitlenize uygun içerik planlaması ve
                            düzenli paylaşımlarla devam eden bir çalışma süreci izliyoruz. Tüm bu süreç boyunca,
                            etkileşimleri ölçerek stratejilerimizi sürekli geliştiriyor ve her adımda dönüşüm
                            oranlarınızı artırmak için çalışıyoruz.</p>
                          <h4 className="title">Çalışma Sürecimiz: Nasıl Çalışıyoruz?</h4>
                          <ul className="liststyle">
                            <li>İhtiyaç Analizi ve Strateji Belirleme</li>
                            <li>Hedef Kitlenin Belirlenmesi ve İçerik Planlaması</li>
                            <li>Düzenli Paylaşımlar ve Topluluk Yönetimi</li>
                            <li>Performans Ölçümü ve Optimizasyon</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                              className="w-100"
                              src={serviceImg2}
                              alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
