import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/kurumsal-kimlik-tasarimi-1.jpg";
import serviceImg2 from "../../assets/images/service/kurumsal-kimlik-tasarimi-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Kurumsal Kimlik Tasarımı Hizmetleri"
            pageDescription="Ketçap Ajans, işletmenize özel logo, kartvizit, kurumsal renkler ve daha fazlasını içeren kurumsal kimlik tasarımı hizmetleri sunar. Markanızı en iyi şekilde temsil edin."
            pageKeyword="kurumsal kimlik tasarımı, logo tasarımı, kartvizit tasarımı, marka renkleri, grafik tasarım, kurumsal tasarım, Ketçap Ajans, marka görselliği"
            pageCanonical="https://ketcap.com/hizmetlerimiz/kurumsal-kimlik-tasarimi"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Kurumsal Kimlik Tasarımı</h2>
                  <p>Markanızın Kimliğini Güçlendiren Yaratıcı Çözümler</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Kurumsal kimlik tasarımı, markanızın görünen yüzüdür ve müşterilerinizle ilk izlenimi oluşturur. Ketçap Ajans olarak, işletmenizin değerlerini, vizyonunu ve hedeflerini en iyi şekilde yansıtan özgün kurumsal kimlik tasarımları sunuyoruz. Logodan kartvizite, broşürlerden dijital görsellere kadar her bir öğede tutarlılığı ve estetiği bir araya getirerek markanızın akılda kalıcı olmasını sağlıyoruz.</p>
                          <h4 className="title">Kurumsal Kimlik Tasarım Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>Marka Analizi:</b> Markanızın kimliğini, hedef kitlenizi ve pazardaki yerinizi analiz ederek ihtiyaçlarınıza uygun bir tasarım yaklaşımı belirliyoruz.</li>
                            <li><b>Kreatif Konsept Geliştirme:</b> Sizi rakiplerinizden ayıran, markanızın değerlerini ve hikayesini anlatan yaratıcı konseptler geliştiriyoruz.</li>
                            <li><b>Tasarım Uygulaması:</b> Logo, kartvizit, antetli kağıt, broşür ve diğer basılı materyallerden dijital görsellere kadar tüm kurumsal kimlik öğelerini özenle tasarlıyoruz.</li>
                            <li><b>Geri Bildirim ve Revizyon:</b> Tasarımlarınızı size sunuyor ve geri bildirimlerinizi alarak son düzenlemeleri yapıyoruz.</li>
                            <li><b>Teslim ve Lansman:</b> Tüm kurumsal kimlik öğelerinizin yüksek kalitede teslimatını gerçekleştiriyor ve markanızın yeni kimliğiyle güçlü bir başlangıç yapmanızı sağlıyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Kurumsal kimlik tasarımında başarılı olmak, markanızın kimliğini ve mesajını net bir şekilde ifade eden bir strateji gerektirir. Ketçap Ajans olarak, markanızın ruhunu ve karakterini en iyi şekilde yansıtan özgün tasarımlar oluşturmak için sürecin her aşamasında titizlikle çalışıyoruz. Analizden uygulamaya, her adımda markanızın hikayesini ve değerlerini ortaya çıkaran bir yaklaşım benimsiyoruz. Amacımız, markanızın görsel dünyasını güçlendirerek müşterilerinizle anlamlı ve uzun vadeli bir bağ kurmaktır.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
