import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn, FaYoutube,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/ketcapcreative", alt: 'Ketçap Facebook' },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/company/ketcapcreative/", alt: 'Ketçap LinkedIn' },
  { Social: <FaInstagram />, link: "https://www.instagram.com/ketcapajans/", alt: 'Ketçap Instagram' },
  { Social: <FaYoutube />, link: "https://www.youtube.com/@ketcapcreative", alt: "Ketçap YouTube" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Hazır mısınız?</span>
                    <h2>
                      Hadi İşe <br /> Başlayalım
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>İletişime Geç</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h3>Hızlı Menü</h3>
                        <ul className="ft-link">
                          <li>
                            <a href="#">Referanslar</a>
                          </li>
                          <li>
                            <a href="/hakkimizda">Hakkımızda</a>
                          </li>
                          <li>
                            <a href="/iletisim">İletişim</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h3>Say Hello</h3>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@ketcap.com">
                              info@ketcap.com
                            </a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2024 Ketçap Creative. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
