import React, {Component} from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";

class Header extends Component {

  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src={logoLight} alt="Ketçap Creative" />;
    } else if (logo === "dark") {
      logoUrl = <img src={logoDark} alt="Ketçap Creative"/>;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src={logoSymbolDark} alt="Ketçap Creative" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img src={logoSymbolLight} alt="Ketçap Creative" />;
    } else {
      logoUrl = <img src={logoDefault} alt="Ketçap Creative" />;
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li className="has-droupdown">
                  <Link to="/">Anasayfa</Link>
                </li>
                <li>
                  <Link to="/hakkimizda">Hakkımızda</Link>
                </li>
                <li className="has-droupdown">
                  <Link to="/hizmetlerimiz">Hizmetlerimiz</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/hizmetlerimiz/sosyal-medya-yonetimi">Sosyal Medya Yönetimi</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/marka-yonetimi">Marka Yönetimi</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/kurumsal-kimlik-tasarimi">Kurumsal Kimlik Tasarımı</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/google-ads-danismanligi">Google Ads Danışmanlığı</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/arama-motoru-optimizasyonu">Arama Motoru Optimization (SEO)</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/web-tasarim">Web Tasarımı</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/e-ticaret-cozumleri">E-Ticaret Çözümleri</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/yazilim-programlama">Yazılım Programlama</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz/grafik-tasarim">Grafik Tasarım</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/iletisim">İletişim</Link>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
