import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/grafik-tasarim-1.jpg";
import serviceImg2 from "../../assets/images/service/grafik-tasarim-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Grafik Tasarım Hizmetleri"
            pageDescription="Ketçap Ajans, işletmeniz için özgün ve yaratıcı grafik tasarım hizmetleri sunar. Logo tasarımı, broşür, sosyal medya görselleri ve daha fazlasıyla markanızı görsel olarak güçlendirin."
            pageKeyword="grafik tasarım, logo tasarımı, broşür tasarımı, sosyal medya görselleri, kreatif tasarım, marka görselliği, Ketçap Ajans, İstanbul"
            pageCanonical="https://ketcap.com/hizmetlerimiz/grafik-tasarim"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--13"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Grafik Tasarım</h2>
                  <p>Markanızın Görsel Kimliğini Güçlendirin</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Görsel iletişim, markanızın hikayesini anlatmanın ve hedef kitlenizle güçlü bir bağ kurmanın en etkili yollarından biridir. Ketçap Ajans olarak, yaratıcı ve özgün grafik tasarım hizmetleri sunarak, markanızın görsel kimliğini güçlendirmeyi hedefliyoruz. Afiş, broşür, katalog, sosyal medya görselleri ve dijital reklamlar gibi tüm mecralarda dikkat çeken ve akılda kalıcı tasarımlar oluşturuyoruz.</p>
                          <h4 className="title">Proceess of metel</h4>
                          <ul className="liststyle">
                            <li><b>İhtiyaç Analizi ve Hedef Belirleme:</b> Markanızın görsel ihtiyaçlarını ve hedef kitlenizi analiz ederek tasarım stratejisini oluşturuyoruz.</li>
                            <li><b>Kreatif Konsept Geliştirme:</b> Markanızı yansıtan özgün ve dikkat çekici tasarım konseptleri geliştiriyoruz.</li>
                            <li><b>Tasarım Uygulaması:</b> Seçilen konsept doğrultusunda, çeşitli platformlar için uygun grafik tasarımlar hazırlıyoruz.</li>
                            <li><b>Revizyon ve Geri Bildirim:</b> Tasarımlarınızı size sunarak, geri bildirimlerinizi alıyor ve gerekli düzenlemeleri yapıyoruz.</li>
                            <li><b>Teslimat ve Lansman:</b> Son tasarımları onayladıktan sonra, tüm dosyaları yüksek kalitede teslim ediyor ve yayınlanması için hazır hale getiriyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Grafik tasarım, markanızın görsel dilini yaratma ve güçlendirme sürecidir. Ketçap Ajans olarak, her projede markanızın hikayesini ve değerlerini en iyi şekilde yansıtan tasarımlar oluşturmak için yaratıcı ve stratejik bir yaklaşım benimsiyoruz. Tasarım sürecinin her aşamasında, hedeflerinize ulaşmanıza yardımcı olacak çözümler sunarak, markanızı görsel olarak daha güçlü ve etkileyici bir konuma taşımak için çalışıyoruz.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
