import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/google-ads-danismanligi-1.jpg";
import serviceImg2 from "../../assets/images/service/google-ads-danismanligi-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Google Ads Danışmanlığı Hizmetleri"
            pageDescription="Ketçap Ajans, işletmenizin çevrimiçi görünürlüğünü artırmak için profesyonel Google Ads danışmanlığı sunar. Hedef kitlenize en doğru şekilde ulaşın ve reklam bütçenizi optimize edin."
            pageKeyword="Google Ads danışmanlığı, Google reklamları, PPC yönetimi, çevrimiçi reklamcılık, dijital pazarlama, reklam optimizasyonu, Ketçap Ajans, Google Ads kampanyaları"
            pageCanonical="https://ketcap.com/hizmetlerimiz/google-ads-danismanligi"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Google Ads Danışmanlığı</h2>
                  <p>Hedef Kitlenize Doğrudan Ulaşmanın En Etkili Yolu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Google Ads, hedef kitlenize doğrudan ulaşmanın ve dönüşüm oranlarınızı artırmanın en hızlı yollarından biridir. Ketçap Ajans olarak, Google Ads kampanyalarınızı profesyonelce yöneterek bütçenizi en verimli şekilde kullanmanızı sağlıyoruz. Rekabetçi analiz, anahtar kelime stratejisi, reklam tasarımı ve performans optimizasyonu gibi her adımda yanınızdayız. Amacımız, reklam yatırımlarınızdan maksimum geri dönüş almanızı sağlamak.</p>
                          <h4 className="title">Google Ads Yönetim Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>Hedef Belirleme ve Analiz:</b> İş hedeflerinizi ve potansiyel müşteri profilinizi belirleyerek, kampanya stratejisini oluşturuyoruz.</li>
                            <li><b>Anahtar Kelime Araştırması:</b> Sektörünüze ve hedef kitlenize uygun, en etkili anahtar kelimeleri analiz ederek stratejilerimizi belirliyoruz.</li>
                            <li><b>Reklam Tasarımı ve Yazımı:</b> İlgi çekici ve dönüşüm odaklı reklam metinleri ve görselleri hazırlıyoruz.</li>
                            <li><b>Kampanya Yönetimi:</b> Google Ads kampanyanızı sürekli olarak izleyip optimize ederek, bütçenizin en verimli şekilde kullanılmasını sağlıyoruz.</li>
                            <li><b>Raporlama ve Optimizasyon:</b> Düzenli raporlamalarla kampanyanın performansını değerlendiriyor ve stratejik düzenlemeler yaparak sürekli iyileştirme sağlıyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Google Ads kampanyalarında başarı, doğru hedefleme, etkili reklam içeriği ve sürekli optimizasyondan geçer. Ketçap Ajans olarak, işinizin büyümesi ve reklam yatırımlarınızdan en yüksek geri dönüşü almanız için detaylı analizler yapıyor ve her kampanya için özel stratejiler geliştiriyoruz. Anahtar kelime araştırmasından reklam yazımına, kampanya yönetiminden performans raporlamasına kadar her adımda profesyonel destek sunarak markanızın dijital dünyada fark edilmesini sağlıyoruz.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
