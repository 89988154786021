import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/sinirlari-zorlayan-reklam-ajansi.jpg";
import findingImg from "../assets/images/about/ketcap-creative-dijital-pazarlama-ajansi.jpg";
import teamImg1 from "../assets/images/team/gulusan-yolcu.jpg";
import teamImg2 from "../assets/images/team/goktay-gurbuzer.jpg";
import teamImg3 from "../assets/images/team/dilara-usta.jpg";
import Helmet from "../component/common/Helmet";

class About extends Component {
  render() {
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Ketçap Ajans",
      "url": "https://www.ketcap.com",
      "logo": "https://www.ketcap.com/ketcap-ajans-logo.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+90-216-999-8768",
        "contactType": "Customer Service"
      },
      "sameAs": [
        "https://www.facebook.com/ketcapcreative",
        "https://www.linkedin.com/company/ketcapcreative/",
        "https://www.instagram.com/ketcapajans/",
        "https://www.youtube.com/@ketcapcreative"
      ]
    };
    let title = "Biz Kimiz?",
      description =
        "Ketçap Ajans, dijital dünyada fark yaratmak isteyen markalar için yaratıcı çözümler sunan enerjik bir dijital pazarlama ajansıdır. Sınırları zorlamayı, yenilikçi fikirlerle her zaman bir adım önde olmayı hedefleyen bir ekibiz. Müşterilerimizin başarısını kendi başarımız olarak görüyor ve bu başarıya ulaşmak için tutkulu bir şekilde çalışıyoruz.";
    return (
        <React.Fragment>
          <Helmet
              pageTitle="Ketçap Ajans - Hakkımızda"
              pageDescription="Ketçap Ajans olarak, dijital pazarlama, sosyal medya yönetimi ve SEO alanında deneyimli bir ekiple hizmet veriyoruz. İşletmenizin dijital dünyada başarılı olmasına yardımcı oluyoruz."
              pageKeyword="Ketçap Ajans, hakkımızda, dijital pazarlama ajansı, sosyal medya yönetimi, SEO uzmanları, dijital dönüşüm, İstanbul"
              pageCanonical="https://ketcap.com/hakkimizda"
          />
          <Header
              headertransparent="header--transparent"
              colorblack="color--black"
              logoname="logo.png"
          />
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
          {/* Start Breadcrump Area */}
          <Breadcrumb title={"Yaratıcılığın Gücüyle Dijital Dünyayı Fethedeceğiz!"} subTitle={"Hakkımızda"}/>
          {/* End Breadcrump Area */}

          {/* Start About Area  */}
          <div className="rn-about-area ptb--120 bg_color--1">
            <div className="rn-about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5">
                    <div className="thumbnail">
                      <img
                          className="w-100"
                          src={about}
                          alt="About Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{title}</h2>
                        <p className="description">{description}</p>
                        <p className="description">Her projemizde yaratıcılığı, stratejik düşünceyle birleştiriyor;
                          markalar için özgün ve etkili dijital deneyimler oluşturuyoruz. Sosyal medya yönetiminden web
                          tasarımına, SEO'dan marka yönetimine kadar geniş bir yelpazede hizmet sunarak, dijital
                          pazarlamanın tüm gücünü markalarınız için kullanıyoruz.</p>
                        <p className="description">Teknolojinin hızla değiştiği günümüzde, biz de bu değişime ayak
                          uydurarak, her zaman en yeni ve en etkili çözümleri sunmaya odaklanıyoruz. Enerjimiz,
                          yaratıcılığımız ve müşteri odaklı yaklaşımımızla, dijital pazarlama dünyasında iz bırakmaya
                          devam ediyoruz.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}

          {/* Start CounterUp Area */}
          <div className="rn-counterup-area pb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight500">Rakamlarla Biz: Dijital Dünyada İz Bırakan Başarılarımız!</h3>
                  </div>
                </div>
              </div>
              <CounterOne/>
            </div>
          </div>
          {/* End CounterUp Area */}

          {/* Start Finding Us Area  */}
          <div className="rn-finding-us-area rn-finding-us bg_color--1">
            <div className="inner">
              <div className="content-wrapper">
                <div className="content">
                  <h4 className="theme-gradient">Bizimle Başarınıza Giden Yolda İlk Adımı Atın!</h4>
                  <p>
                    Ketçap Ajans olarak, markanızı dijital dünyada bir adım öne çıkaracak stratejilerle donatıyoruz.
                    Yaratıcılığımız ve tecrübemizle size özel çözümler sunmak için buradayız. Hemen iletişime geçin ve
                    işinizi bir sonraki seviyeye taşıyacak süreci başlatın.
                  </p>
                  <a className="rn-btn btn-white" href="/iletisim">
                    Hemen Başlayın
                  </a>
                </div>
              </div>
              <div className="thumbnail">
                <div className="image">
                  <img src={findingImg} alt="Finding Images"/>
                </div>
              </div>
            </div>
          </div>
          {/* End Finding Us Area  */}

          {/* Start Team Area  */}
          <div className="rn-team-area bg_color--1 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--3 text-center mb--25">
                    <h2 className="title">Ekibimizle Tanışın</h2>
                    <p>
                      Bizimle aynı tutkuyu paylaşan yetenekli profesyonellerden oluşan bir ekiple çalışmaktan gurur
                      duyuyoruz. Her biri kendi alanında uzman olan ekibimiz, yenilikçi çözümler üretmek ve
                      müşterilerimize en iyi hizmeti sunmak için bir araya geldi. Ekibimizi tanıyın ve başarı
                      hikayemizin arkasındaki isimleri keşfedin.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Start Single Team  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                          className="w-100"
                          src={teamImg1}
                          alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Gülüşan Yolcu</h4>
                      <p className="designation">Direktör</p>
                    </div>
                    <ul className="social-icon">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF/>
                        </a>
                      </li>
                      <li>
                        <a href="http://linkedin.com/">
                          <FaLinkedinIn/>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/">
                          <FaTwitter/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Team  */}

                {/* Start Single Team  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                          className="w-100"
                          src={teamImg2}
                          alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Göktay Gürbüzer</h4>
                      <p className="designation">Yazılım Geliştirici</p>
                    </div>
                    <ul className="social-icon">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF/>
                        </a>
                      </li>
                      <li>
                        <a href="http://linkedin.com/">
                          <FaLinkedinIn/>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/">
                          <FaTwitter/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Team  */}

                {/* Start Single Team  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                          className="w-100"
                          src={teamImg3}
                          alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Z. Dilara Usta</h4>
                      <p className="designation">Görsel Tasarım Uzmanı</p>
                    </div>
                    <ul className="social-icon">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF/>
                        </a>
                      </li>
                      <li>
                        <a href="http://linkedin.com/">
                          <FaLinkedinIn/>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/">
                          <FaTwitter/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Team  */}
              </div>
            </div>
          </div>
          {/* End Team Area  */}

          {/* Start Brand Area */}
          <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <BrandTwo/>
                </div>
              </div>
            </div>
          </div>
          {/* End Brand Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp/>
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer/>
        </React.Fragment>
    );
  }
}

export default About;
