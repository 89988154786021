import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/arama-motoru-optimizasyonu-1.jpg";
import serviceImg2 from "../../assets/images/service/arama-motoru-optimizasyonu-2.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Arama Motoru Optimizasyonu (SEO) Hizmetleri"
            pageDescription="Ketçap Ajans, web sitenizin arama motorlarında üst sıralarda yer alması için kapsamlı SEO hizmetleri sunar. Organik trafik ve görünürlüğünüzü artırın, hedef kitlenize ulaşın."
            pageKeyword="Ketçap Ajans, web sitenizin arama motorlarında üst sıralarda yer alması için kapsamlı SEO hizmetleri sunar. Organik trafik ve görünürlüğünüzü artırın, hedef kitlenize ulaşın."
            pageCanonical="https://ketcap.com/hizmetlerimiz/arama-motoru-optimizasyonu"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--9"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Arama Motoru Opitimzasyonu (SEO)</h2>
                  <p>Web Sitenizi Zirveye Taşıyın!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>SEO (Arama Motoru Optimizasyonu), web sitenizin arama motorlarında üst sıralarda yer almasını sağlayarak daha fazla ziyaretçiye ulaşmanızı ve çevrimiçi görünürlüğünüzü artırmanızı sağlar. Ketçap Ajans olarak, SEO alanında en güncel teknikleri ve stratejileri kullanarak sitenizin performansını maksimize ediyoruz. Organik trafik elde etmek ve dönüşüm oranlarınızı artırmak için anahtar kelime analizi, içerik optimizasyonu, teknik SEO ve bağlantı kurma stratejileri sunuyoruz.</p>
                          <h4 className="title">SEO Yönetim Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>Anahtar Kelime ve Rakip Analizi:</b> Hedef kitlenize ve sektörünüze uygun anahtar kelimeleri ve rakiplerinizin performansını analiz ediyoruz.</li>
                            <li><b>İçerik Optimizasyonu:</b> Web sitenizin içeriklerini, kullanıcı deneyimini ve arama motorlarının algoritmalarını göz önünde bulundurarak optimize ediyoruz.</li>
                            <li><b>Teknik SEO:</b> Sayfa hızından mobil uyumluluğa, site haritasından meta etiketlere kadar tüm teknik SEO unsurlarını geliştiriyoruz.</li>
                            <li><b>Bağlantı Kurma (Link Building): </b>Web sitenizin otoritesini artırmak için kaliteli geri bağlantılar (backlinks) oluşturuyoruz.</li>
                            <li><b>Performans İzleme ve Raporlama:</b> Sürekli olarak SEO performansınızı izliyor, raporluyor ve stratejilerimizi ihtiyaçlarınıza göre güncelliyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>SEO, sürekli takip ve iyileştirme gerektiren bir süreçtir. Ketçap Ajans olarak, web sitenizin arama motorlarındaki sıralamasını iyileştirmek için kapsamlı analizler ve stratejiler geliştiriyoruz. Her adımda, markanızın dijital görünürlüğünü ve otoritesini artırmak için çalışıyoruz. İçerik optimizasyonundan teknik geliştirmelere, bağlantı kurmadan performans raporlamasına kadar her aşamada profesyonel destek sunarak web sitenizin hak ettiği yere ulaşmasını sağlıyoruz.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
