import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Yaratıcı Dijital Pazarlama ve Web Çözümleri</title>
                    <meta name="description" content={this.props.pageDescription}/>
                    <meta name="keywords" content={this.props.pageKeyword}/>
                    <link rel="canonical" href={this.props.pageCanonical}/>
                    <meta name="robots" content="index follow"/>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
