import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 40.99229755946458,
            lng: 29.118573781383393
        },
        zoom: 16
    };

    render(){
        return(
            <React.Fragment>
                <Helmet
                    pageTitle="Ketçap Ajans - İletişim"
                    pageDescription="Ketçap Ajans ile iletişime geçin. Hizmetlerimiz hakkında detaylı bilgi almak ve iş birliği fırsatlarını keşfetmek için bize ulaşın."
                    pageKeyword="iletişim, Ketçap Ajans iletişim, müşteri hizmetleri, iletişim bilgileri, dijital pazarlama ajansı iletişim, iş birliği, Ketçap Ajans, İstanbul"
                    pageCanonical="https://ketcap.com/iletisim"
                />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Bize Ulaşın</h2>
                                    <p>Markanızı dijital dünyada zirveye taşımaya hazır mısınız? Her türlü sorunuz, talebiniz veya işbirliği öneriniz için bize ulaşın. Yaratıcı çözümlerimiz ve profesyonel ekibimizle size en kısa sürede geri dönüş yapacağız.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefonla İletişim</h4>
                                        <p><a href="tel:+902169998768">+90 216 999 87 68</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">E-posta Adresi</h4>
                                        <p><a href="mailto:info@ketcap.com">info@ketcap.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ofis Adresi</h4>
                                        <p>Atatürk Mah. Ata Blokları 2-3 <br/> Kat: 2, Ofis No:25<br /> Ataşehir/İstanbul</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            bootstrapURLKeys={{ key: "AIzaSyCboOs7m3cCsczipPw37CxOBjNBH8Bd4Go" }}
                            lat={40.99224897103698}
                            lng={29.118579145527075}
                            text="Ketçap Ajans"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
                {/* End Contact Map  */}
                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact