import React, { Component } from "react";

import aboutImg from "../../../assets/images/about/ketcap-dijital-pazarlama-ajansi.jpg";

class About extends Component {
  render() {
    let title = "Biz Kimiz?",
      description =
        "Ketçap Ajans, dijital dünyada fark yaratan yenilikçi çözümler sunar. Markanızın dijital varlığını güçlendirmek için yaratıcı stratejiler geliştiriyoruz. Deneyimli ekibimizle, işletmenizin ihtiyaçlarına özel çözümler sunarak, işinizi bir üst seviyeye taşıyoruz.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Ne Yapıyoruz?</h3>
                        <p>
                          Yaratıcı pazarlama stratejileriyle markanızı dijital dünyada öne çıkarır, hedef kitlenizle etkili bir şekilde buluştururuz.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Nasıl Yapıyoruz?</h3>
                        <p>
                          Veriye dayalı analizler ve yenilikçi teknolojiler kullanarak, her adımı titizlikle planlayıp uygularız.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
