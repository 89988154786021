import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component{

    render(){
        const PostList = BlogContent.slice(0 , 3);
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Ketçap Ajans",
            "image": "https://ketcap.com/logo.png",
            "@id": "https://ketcap.com",
            "url": "https://ketcap.com",
            "telephone": "+90-216-999-8768",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Atatürk Mah. Ata Blokları 2-3 Kat: 2, Ofis No:25 Ataşehir/İstanbul",
                "addressLocality": "İstanbul",
                "postalCode": "34758",
                "addressCountry": "TR"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": 40.99229755946458,
                "longitude": 29.118573781383393
            },
            "openingHoursSpecification": [
                {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                    ],
                    "opens": "09:00",
                    "closes": "18:00"
                }
            ],
            "sameAs": [
                "https://www.facebook.com/ketcapcreative",
                "https://www.linkedin.com/company/ketcapcreative/",
                "https://www.instagram.com/ketcapajans/"
            ]
        };
        return (
            <div className="active-dark">
                <Helmet
                    pageTitle="Ketçap Ajans - Dijital Pazarlama ve Sosyal Medya Yönetimi Hizmetleri"
                    pageDescription="Ketçap Ajans, işletmeniz için dijital pazarlama stratejileri, sosyal medya yönetimi ve SEO çözümleri sunar. Dijital dünyada büyümek için doğru adrestesiniz."
                    pageKeyword="dijital pazarlama, sosyal medya yönetimi, SEO hizmetleri, içerik pazarlaması, marka yönetimi, dijital ajans, İstanbul, Ketçap Ajans"
                    pageCanonical="https://ketcap.com"
                />
                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />
                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne/>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120 bg_color--1">
                    <About/>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo/>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Rakamlarla Ketçap</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne/>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </div>
        );
    }
}

export default MainDemo;