import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";


const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Sosyal Medya Yönetimi',
        description: 'Sosyal medya hesaplarınızın profesyonel bir şekilde yönetilmesi, hedef kitlenize ulaşmanız ve marka bilinirliğinizi artırmanız için stratejik içerik planlaması ve performans takibi.',
        link: 'hizmetlerimiz/sosyal-medya-yonetimi'
    },
    {
        icon: <FiLayers />,
        title: 'Marka Yönetimi',
        description: 'Marka kimliğinizi güçlendirmek ve hedef kitlenize doğru bir şekilde ulaşmak için kapsamlı stratejiler geliştiriyoruz. Sürdürülebilir bir marka imajı yaratmanıza yardımcı oluyoruz.',
        link: 'hizmetlerimiz/marka-yonetimi'
    },
    {
        icon: <FiUsers />,
        title: 'Kurumsal Kimlik Tasarımı',
        description: 'İşletmenizin vizyon ve değerlerini yansıtan özgün kurumsal kimlik tasarımları. Logo, kartvizit, antetli kağıt ve diğer tüm basılı materyallerde yaratıcı çözümler sunuyoruz.',
        link: 'hizmetlerimiz/kurumsal-kimlik-tasarimi'
    },
    {
        icon: <FiMonitor />,
        title: 'Google Ads Danışmanlığı',
        description: 'Google Ads kampanyalarınızı en etkili şekilde yöneterek bütçenizi verimli kullanmanıza ve dönüşüm oranlarınızı artırmanıza yardımcı oluyoruz.',
        link: 'hizmetlerimiz/google-ads-danismanligi'
    },
    {
        icon: <FiCast />,
        title: 'Arama Motoru Optimizasyonu (SEO)',
        description: 'Web sitenizin organik arama sonuçlarında üst sıralarda yer almasını sağlamak için en güncel SEO tekniklerini ve stratejilerini uyguluyoruz.',
        link: 'hizmetlerimiz/arama-motoru-optimizasyonu'
    },
    {
        icon: <FiMonitor />,
        title: 'Web Tasarımı',
        description: 'İşletmenize özel, kullanıcı dostu ve mobil uyumlu web siteleri tasarlıyoruz. Estetik ve işlevselliği bir araya getirerek, çevrimiçi varlığınızı güçlendiriyoruz.',
        link: 'hizmetlerimiz/web-tasarim'
    },
    {
        icon: <FiMonitor />,
        title: 'E-Ticaret Çözümleri',
        description: 'Online mağazanızın kurulumu, yönetimi ve optimizasyonu için kapsamlı e-ticaret çözümleri sunuyoruz. Satışlarınızı artırmak için stratejik planlamalar yapıyoruz.',
        link: 'hizmetlerimiz/e-ticaret-cozumleri'
    },
    {
        icon: <FiMonitor />,
        title: 'Yazılım Programlama',
        description: 'Özel yazılım ihtiyaçlarınız için çözümler geliştiriyoruz. İş süreçlerinizi otomatikleştirerek verimliliğinizi artırmanızı sağlıyoruz.',
        link: 'hizmetlerimiz/yazilim-programlama'
    },
    {
        icon: <FiMonitor />,
        title: 'Grafik Tasarım',
        description: 'Görsel iletişim ihtiyaçlarınızı karşılamak için yaratıcı grafik tasarım hizmetleri sunuyoruz. Afiş, broşür, katalog ve sosyal medya görselleri gibi birçok alanda özgün tasarımlar.',
        link: 'hizmetlerimiz/grafik-tasarim'
    }
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet
                    pageTitle="Ketçap Ajans - Dijital Pazarlama ve Sosyal Medya Yönetimi Hizmetlerimiz"
                    pageDescription="Ketçap Ajans, dijital pazarlama, sosyal medya yönetimi, SEO, içerik pazarlaması ve marka yönetimi gibi geniş kapsamlı hizmetler sunarak işletmenizin dijital dünyada fark yaratmasına yardımcı olur."
                    pageKeyword="dijital pazarlama hizmetleri, sosyal medya yönetimi, SEO, içerik pazarlaması, marka yönetimi, dijital strateji, Ketçap Ajans, İstanbul"
                    pageCanonical="https://ketcap.com/hizmetlerimiz"
                />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Hizmetlerimiz'} subTitle={'Verdiğimiz Hizmetler'}  />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Hizmetlerimizle İşinizi Bir Adım Öteye Taşıyın!</h2>
                                    <p>Ketçap Ajans olarak, markanızın dijital dünyada parlaması için gereken tüm
                                        yaratıcı çözümleri ve stratejileri sunuyoruz. İhtiyacınıza uygun hizmetlerimizle
                                        yanınızdayız, çünkü sizin başarınız, bizim işimiz.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.link}>
                                        <div className="service service__style--2">
                                        <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;