import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonialImg1 from "../assets/images/client/testimonial-1.jpg";
import testimonialImg2 from "../assets/images/client/testimonial-2.jpg";
import testimonialImg3 from "../assets/images/client/testimonial-3.jpg";
import testimonialImg4 from "../assets/images/client/testimonial-4.jpg";
import testimonialImg5 from "../assets/images/client/testimonial-5.jpg";
import testimonialImg6 from "../assets/images/client/testimonial-6.jpg";
import testimonialImg7 from "../assets/images/client/testimonial-7.jpg";
import testimonialImg8 from "../assets/images/client/testimonial-8.jpg";

class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Ketçap Ajans ile çalışmak, markamızın dijital dünyada hızlı bir şekilde büyümesini sağladı. Profesyonellikleri ve yaratıcı yaklaşımları takdire şayan.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Ahmet Yılmaz </span> - Pazarlama Müdürü, XYZ Teknoloji.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Web tasarım ve SEO hizmetleri, online satışlarımızda büyük bir artış sağladı. Ekibin her aşamadaki desteği için teşekkür ederiz.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Selin Aksoy </span> - CEO, Aksoy Mobilya.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Sosyal medya yönetimi konusunda sundukları stratejilerle markamızı hedef kitlemize en etkili şekilde ulaştırdık. Harika bir iş çıkardılar.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Murat Demir </span> - İş Geliştirme Müdürü, Demir İnşaat.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Google Ads kampanyaları sayesinde kısa sürede büyük geri dönüşler aldık. Ketçap Ajans ile çalışmak, şirketimiz için çok değerli oldu.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Elif Kara </span> - Pazarlama Direktörü, Kara Sigorta.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Kurumsal kimlik tasarımı konusunda aldığımız hizmet, markamızın görsel kimliğini güçlendirdi. Her detayda profesyonelliklerini gösterdiler.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Mehmet Öztürk </span> - Genel Müdür, Öztürk Giyim.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      E-ticaret çözümleri ile online satışlarımızı önemli ölçüde artırdık. Kullanıcı dostu ve etkili bir platform sağladılar.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Zeynep Çelik </span> - Satış Müdürü, Çelik Otomotiv.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Yazılım programlama hizmetleri, projelerimizin tam zamanında ve mükemmel şekilde tamamlanmasını sağladı. Kesinlikle tavsiye ederim.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Ali Vural </span> - CTO, Vural Yazılım.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Marka yönetimi konusundaki uzmanlıkları ile markamızı sektörde güçlü bir konuma taşıdık. Ketçap Ajans ile çalışmak bir ayrıcalık.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Ayşe Koç</span> - Marka Yöneticisi, Koç Elektronik.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg1} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg2} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg3} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg4} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg5} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg6} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg7} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg8} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
