import blog1 from "../../assets/images/blog/blog-01.jpg";
import blog2 from "../../assets/images/blog/blog-02.jpg";
import blog3 from "../../assets/images/blog/blog-03.jpg";
import blog4 from "../../assets/images/blog/blog-04.jpg";

const BlogContent = [
  {
    images: blog1,
    title: " Yeni Sosyal Medya Kampanyamız Büyük İlgi Gördü",
    category: "Sosyal Medya",
  },
  {
    images: blog2,
    title: "E-Ticaret Sitesi Tasarımımız Ödül Kazandı",
    category: "Web Tasarım",
  },

  {
    images: blog3,
    title: "SEO Çalışmalarımızla Yeni Başarılar Elde Ettik",
    category: "SEO",
  },
  {
    images: blog4,
    title: " Google Ads Kampanyaları ile Müşteri Kitlenizi Genişletin",
    category: "Dijital Pazarlama",
  },
  {
    images: blog1,
    title: "Kurumsal Kimlik Tasarımı ile Marka İmajınızı Güçlendirin",
    category: "Marka Yönetimi",
  },
  {
    images: blog2,
    title: "SEO ile Web Sitenizin Görünürlüğünü Artırın",
    category: "SEO",
  },
];

export default BlogContent;