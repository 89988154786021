import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

import serviceImg from "../../assets/images/service/web-tasarim-programlama-2.jpg";
import serviceImg2 from "../../assets/images/service/web-tasarim-programlama-1.jpg";
import Helmet from "../../component/common/Helmet";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet
            pageTitle="Ketçap Ajans - Profesyonel Web Tasarım Hizmetleri"
            pageDescription="Ketçap Ajans, işletmeniz için kullanıcı dostu ve estetik web siteleri tasarlar. Mobil uyumlu, hızlı ve SEO dostu web tasarımı ile çevrimiçi varlığınızı güçlendirin."
            pageKeyword="web tasarım, web sitesi tasarımı, mobil uyumlu tasarım, kullanıcı deneyimi, UI/UX tasarım, SEO dostu web tasarımı, Ketçap Ajans, İstanbul"
            pageCanonical="https://ketcap.com/hizmetlerimiz/web-tasarim"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--10"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Web Tasarımı</h2>
                  <p>Markanızı Dijital Dünyada Etkili Kılın!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Web siteniz, markanızın dijital dünyadaki en önemli yüzüdür. Ketçap Ajans olarak, markanızın ihtiyaçlarına ve hedeflerine uygun, kullanıcı dostu ve estetik açıdan güçlü web tasarımları sunuyoruz. Mobil uyumlu, hızlı ve SEO dostu web siteleri tasarlayarak, ziyaretçilerinizi müşteriye dönüştürmenizi sağlıyoruz. Kullanıcı deneyimini ön planda tutan yaratıcı çözümlerimizle, dijital dünyada fark edilmenizi sağlıyoruz.</p>
                          <h4 className="title">Web Tasarım Sürecimiz</h4>
                          <ul className="liststyle">
                            <li><b>İhtiyaç Analizi ve Planlama:</b> İşletmenizin ihtiyaçlarını ve hedef kitlenizin beklentilerini analiz ederek tasarım sürecine başlıyoruz.</li>
                            <li><b>Kreatif Konsept ve Tasarım:</b> Markanızı en iyi yansıtan, özgün ve ilgi çekici tasarım konseptleri geliştiriyoruz.</li>
                            <li><b>Mobil Uyum ve Kullanıcı Deneyimi (UX):</b> Web sitenizin her cihazda kusursuz çalışması için mobil uyumlu ve kullanıcı dostu bir yapı oluşturuyoruz.</li>
                            <li><b>Geliştirme ve Optimizasyon:</b> Modern teknolojilerle kodlama yaparak, sitenizin performansını ve hızını optimize ediyoruz.</li>
                            <li><b>Test ve Yayına Alma:</b> Web sitenizin tüm fonksiyonlarını ve uyumluluğunu test ettikten sonra, sorunsuz bir şekilde yayına alıyoruz.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Web tasarımı, markanızın dijital dünyada güçlü bir varlık göstermesi için kritik bir süreçtir. Ketçap Ajans olarak, kullanıcı deneyimini ve estetiği ön planda tutarak, markanızın kimliğine uygun web siteleri tasarlıyoruz. İhtiyaç analizinden başlayarak, konsept geliştirme, tasarım, test ve yayınlama aşamalarının her birinde titizlikle çalışarak, dijital dünyada rekabetçi olmanızı sağlıyoruz. Web sitenizle ziyaretçilerinize unutulmaz bir deneyim sunmak için buradayız.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
