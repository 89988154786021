import React, { Component } from "react";

import brand1 from "../assets/images/brand/manga.png";
import brand2 from "../assets/images/brand/ecovis.png";
import brand3 from "../assets/images/brand/mental.png";
import brand4 from "../assets/images/brand/mr-jade.png";
import brand5 from "../assets/images/brand/gravital.jpg";
import brand6 from "../assets/images/brand/standby.jpg";
import brand7 from "../assets/images/brand/skyart.jpg";
import brand8 from "../assets/images/brand/boreas-lojistik.png";
import brand9 from "../assets/images/brand/sefer.png";
import brand10 from "../assets/images/brand/krb.png";
import brand11 from "../assets/images/brand/dehatech.jpeg";
import brand12 from "../assets/images/brand/mca.jpeg";
import brand13 from "../assets/images/brand/solarra.png";
import brand14 from "../assets/images/brand/future.png";
import brand15 from "../assets/images/brand/gurer.png";
import brand16 from "../assets/images/brand/oblio.png";



class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand2} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand3} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand4} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand5} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand6} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand7} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand8} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand9} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand10} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand11} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand12} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand13} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand14} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand15} alt="Logo Images"/>
          </li>
          <li>
            <img src={brand16} alt="Logo Images"/>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default BrandTwo;
